@import "~bootstrap/scss/bootstrap";
@import "~select2/dist/css/select2.min.css";
@import "~select2-bootstrap-theme/dist/select2-bootstrap.min.css";

$fa-font-path: "/fonts/";
@import "~@fortawesome/fontawesome-pro/css/all";

// Custom styles
body {
  background-color: #ecf0f5;
}

header {
  a:hover {
    text-decoration: none;
  }

}

.content {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.logo img {
  height: 60px;
}

label.required:after {
  content: '*';
  color: #cc4b37;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  margin-left: 4px;
}

.was-validated .form-check-input:invalid {
  outline: 1px solid #dc3545;
}

input[type=search] {
  height: auto;
  margin: 0;
}

.color--white,
.color--white:hover {
  color: #ffffff;
}

.qrcode-scanner {
  align-items: center;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;

  .qrcode-scanner-overlay {
    align-items: center;
    color: #ffffff;
    content: ' ';
    display: flex;
    flex-direction: column;
    font-size: 1.25em;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    z-index: 2;
  }

  video {
    max-width: 100%;
    min-height: 50vh;
    max-height: 70vh;
    position: relative;

    @media (min-width: 768px) {
      min-height: initial;
    }
  }

}

.scan-region-highlight {
  border-radius: 10px;
  outline: rgba(0, 0, 0, .4) solid 50vmax;
}

.scan-region-highlight-svg {
  display: none;
}

.tablesorter thead .disabled {
  display: none
}

@include media-breakpoint-down(xs) {
  body {
     background: #fff;
  }

  .content {
    box-shadow: none;
  }

}
